
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.destination-choice {
  &__title {
    text-align: left;
  }
  &__item {
    position: relative;
    display: block;
    padding-bottom: 20px;
    &:first-child {
      z-index: 4;
    }
    &:nth-child(2) {
      z-index: 3;
    }
    &:nth-child(3) {
      z-index: 2;
    }
    &:nth-child(4) {
      z-index: 1;
    }
  }
  &__actions {
    display: flex;
    justify-content: space-between;
  }
}
