
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.city-search {
  position: relative;
  z-index: 4;

  &--open {
    z-index: 5;
  }
  &__list {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 2;
    width: 100%;
    max-height: 180px;
    overflow: hidden;
    background-color: $color-white;
    border-radius: 8px;
    box-shadow: 0 1px 6px rgb(0 0 0 / 20%);
    .select--open & {
      max-height: 300px;
    }
  }
  &__list-inner {
    width: 100%;
    height: auto;
    max-height: 180px;
    overflow: auto;
    .select--open & {
      max-height: 300px;
    }
  }
  &__list-item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    line-height: normal;
    padding: 8px 16px;
    color: $color-grayscale-60;
    font-size: 14px;
    font-weight: 300;
    cursor: pointer;
    background-color: $color-white;
    &:hover {
      background-color: $color-grayscale-05;
    }
  }
  &__name {
    display: inline-block;
    margin-right: 6px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }
  &__district {
    color: $secondary-color;
  }
}

